<template>
  <div class="mobile-detail-button">
    <div v-for="(button, index) in buttons" :key="index" class="button" :class="getColor(button)" @click="$emit('button-click', button)">
      {{ button }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      default: () => {
        return ["提交保存"];
      },
    },
  },
  methods: {
    getColor: function (button) {
      if (button.indexOf("提交") > -1) {
        return "base-bg-color";
      }
      if (button.indexOf("保存") > -1 || button.indexOf("催办") > -1 || button.indexOf("取消") > -1) {
        return "second-bg-color";
      }
      if (button.indexOf("拒绝") > -1) {
        return "red-bg-color";
      }
      return "base-bg-color";
    },
  },
};
</script>